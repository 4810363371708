import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.css';

import ReactQuill from 'react-quill';

type RichTextEditorProps = {
  value?: string;
  setValue: (value: string) => void;
  placeholder?: string;
};

export const RichTextEditor = ({
  value,
  setValue,
  placeholder,
}: RichTextEditorProps) => (
  <ReactQuill
    id="richTextEditor"
    theme="snow"
    value={value}
    onChange={setValue}
    placeholder={placeholder}
  />
);
