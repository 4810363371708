import { ApolloError } from '@apollo/client';
import OverviewPage from 'components/OverviewPage';
import { SelectButtonOption } from 'components/SelectButton';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import { EmptyStateMessage } from 'components/TableRefactor/TableEmptyState';
import {
  HeaderConfig,
  RowConfig,
  ViewConfig,
} from 'components/TableRefactor/types';
import { GET_PUBLIC_ISSUES } from 'graphql/publicHealth/internal/queries';
import { Issue } from 'models/issue';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import { IssueCategory, mapIssueCategories } from 'shared/mapIssueCategories';
import { getIssueStatus } from 'shared/mapIssueStatus';

const NUMBER_OF_ISSUES_PER_PAGE = 12;

const filterStatusOptions: SelectButtonOption[] = [
  {
    title: 'Unresolved',
    label: 'Unresolved',
    id: 'open',
  },
  {
    label: 'Customer contacted',
    title: 'Customer...',
    id: 'customercontacted',
  },
  {
    title: 'Resolved',
    label: 'Resolved',
    id: 'resolved',
  },
];

const filterProviderOptions: SelectButtonOption[] = [
  {
    title: 'AOK',
    label: 'AOK',
    id: 'aok',
  },
  {
    title: 'Barmer',
    label: 'Barmer',
    id: 'barmer',
  },
  {
    title: 'DAK',
    label: 'DAK',
    id: 'dak',
  },
  {
    title: 'TK',
    label: 'TK',
    id: 'tk',
  },
];

const filterTypeOptions: SelectButtonOption[] = [
  {
    title: 'Unicheck',
    label: 'Unicheck',
    id: 'unicheck',
  },
  {
    title: 'Missing information',
    label: 'Missing information',
    id: 'missinginfo',
  },
  {
    title: 'Canceled',
    label: 'Canceled',
    id: 'canceled',
  },
  {
    title: 'Not eligible',
    label: 'Not eligible',
    id: 'noteligible',
  },
  {
    title: 'Processing',
    label: 'Processing',
    id: 'processing',
  },
  {
    title: 'Other',
    label: 'Other',
    id: 'other',
  },
];

const filterOccupationOptions: SelectButtonOption[] = [
  {
    title: 'Employed',
    label: 'Employed',
    id: 'employed',
  },
  {
    title: 'Self-employed',
    label: 'Self-employed',
    id: 'self-employed',
  },
  {
    title: 'Student',
    label: 'Student',
    id: 'student',
  },
];

const filterCustomerContactedOption: SelectButtonOption[] = [
  {
    title: 'Any',
    label: 'Any',
    id: 'any',
  },
  {
    title: 'Yes',
    label: 'Yes',
    id: 'yes',
  },
  {
    title: 'No',
    label: 'No',
    id: 'no',
  },
];

const filterViewOptions = [
  {
    id: 'all',
    label: 'All issues',
    title: 'All issues',
    show: true,
  },
  {
    id: 'action-pending',
    label: 'Action needed',
    title: 'Action needed',
    show: true,
  },
];

const PublicHealthIssuesPage = () => {
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const defaultEmptyStateMessage: EmptyStateMessage = {
    title: 'There are no issues yet.',
    description: 'It looks like there are no public health issues yet.',
  };

  const tableFilters: FilterConfig[] = [
    {
      options: filterStatusOptions,
      filterType: 'MULTIPLE',
      label: 'Status',
      id: 'filterStatus',
      placeholder: 'Select status(es)',
    },
    {
      options: filterProviderOptions,
      filterType: 'MULTIPLE',
      label: 'Provider',
      id: 'filterProvider',
      placeholder: 'Select provider(s)',
    },
    {
      options: filterTypeOptions,
      filterType: 'MULTIPLE',
      label: 'Issue type',
      id: 'filterType',
      placeholder: 'Select issue type(s)',
    },
    {
      options: filterOccupationOptions,
      filterType: 'MULTIPLE',
      label: 'User occupation',
      id: 'filterOccupation',
      placeholder: 'Select user occupation(s)',
    },
    {
      filterType: 'DATE_RANGE',
      label: 'Last update on',
      id: 'updatedOn',
    },
    {
      options: filterCustomerContactedOption,
      filterType: 'TEXT',
      label: 'Contacted by provider?',
      id: 'isCustomerContacted',
    },
  ];

  const viewConfig: ViewConfig = {
    id: 'filterView',
    options: filterViewOptions,
  };

  const tableHeaders: HeaderConfig[] = [
    {
      id: 'type',
      label: 'Issue type',
      width: 'w-none',
      minWidth: 'min-w-[180px]',
      sticky: true,
      threshold: 'left-0',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'status',
      label: 'Issue status',
      width: 'w-none',
      minWidth: 'min-w-[160px]',
      sticky: true,
      threshold: 'left-[180px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'description',
      label: 'Description',
      width: 'w-none',
      minWidth: 'min-w-[151px]',
      sticky: true,
      threshold: 'left-[340px]',
      border: 'right',
      component: ColumnHeader,
    },
    {
      id: 'comments',
      label: 'Comments',
      width: 'w-none',
      minWidth: 'min-w-[116px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'provider',
      label: 'Provider',
      width: 'w-none',
      minWidth: 'min-w-[116px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'first-name',
      label: 'First name',
      width: 'w-none',
      minWidth: 'min-w-[132px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'last-name',
      label: 'Last name',
      width: 'w-none',
      minWidth: 'min-w-[132px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'updated-on',
      label: 'Last update',
      width: 'w-none',
      minWidth: 'min-w-[125px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
  ];

  const rowConfig: RowConfig = {
    getResourceLink: (data: Issue) =>
      `${generatePath(paths.policies.publicHealth.internal.details.path, {
        id: data.userPolicy?.id,
      })}#activity`,
    cells: [
      {
        type: 'TEXT_WITH_CHECK',
        textColor: 'dark',
        props: {
          getTextWithCheck: (data: Issue) => ({
            label: mapIssueCategories(data.category as IssueCategory),
            check: data.isCustomerInformed ? 'check' : 'cross',
          }),
        },
      },
      {
        type: 'STATUS',
        props: {
          getStatus: (data: Issue) => getIssueStatus(data.status),
        },
        textColor: 'dark',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: Issue) =>
            data.description.length > 25
              ? `${data.description.slice(0, 25)}...`
              : data.description,
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: Issue) => data.numberOfComments?.toString() ?? '0',
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: Issue) => data.numberOfComments?.toString() ?? '0',
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: Issue) => data.userPolicy?.user.firstName ?? '',
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: Issue) => data.userPolicy?.user.lastName ?? '',
        },
      },
      {
        type: 'DATE',
        props: {
          getDate: (data: Issue) => data.userPolicy?.updatedAt ?? '',
        },
        textColor: 'light',
      },
    ],
  };

  return (
    <OverviewPage
      current="issues-public"
      title="All issues - Feather Admin Panel"
      error={error}
    >
      <Table
        title="Public health issues"
        query={GET_PUBLIC_ISSUES}
        itemsPerPage={NUMBER_OF_ISSUES_PER_PAGE}
        searchPlaceholder="Search by name or email"
        headers={tableHeaders}
        rowConfig={rowConfig}
        emptyStateLabel={defaultEmptyStateMessage}
        filterConfig={tableFilters}
        viewConfig={viewConfig}
        onError={setError}
      />
    </OverviewPage>
  );
};

export default PublicHealthIssuesPage;
